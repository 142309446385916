import Vue from 'vue'
import './plugins'
import App from './App'
import router from './router'
import store from './store'
import moment from 'moment'
import 'vuetify/dist/vuetify.min.css'
import VueMask from 'v-mask'
import('./css/main.css')
import Print from 'vue-print-nb'
import vuetify from './plugins/vuetify'; // Importando a configuração do Vuetify

Vue.use(VueMask);
Vue.use(Print);
Vue.config.productionTip = false;

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY');
  }
});

router.beforeEach((to, from, next) => {
  if (to.path !== '/') {
    const user = JSON.parse(sessionStorage.getItem('user'));

    if (to.path !== '/privacidade' && !user) {
      return next('/');
    }
  }

  next();
});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
